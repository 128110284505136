html {width: 100vw; overflow-x: hidden;}
body {
    padding-top: $header-height;
    font-family: $primary-font;
    font-weight: 300;
    color: #000;
    width:100vw; overflow-x: hidden;
}

blockquote
{
  font-style: normal;
  font-size: 20px;
  margin-left: 32px;
  border-left: 4px solid;
  border-color: inherit;
  padding-left: 8px;
  font-weight: 400;
}

.avis-widget-btn {
  position: fixed;
  z-index: 100;
  top: 15vh;
}

.lettrine:first-letter {
  font-size : 30px;
  float : left;
}

.height-40vh {height:40vh; overflow:hidden;}
.height-30vh {height:30vh; overflow:hidden;}
.height-300 {height: 300px;}

.font-bold {font-weight: bold;}

.font-semibold {font-weight: 400!important;}

.vw-100 {width: 100vw;}
.vh-100 {height: 100vh;}
.vh-90 {height: 90vh;}
.vh-80 {height: 80vh;}

h1 {
    font-family: $secondary-font;
    font-weight: 300;
    color : $color-secondary;
    margin-bottom: 30px; font-size: 34px!important;
}
h2 {font-family: $primary-font;
    font-weight: 300;
    color : #000;
    margin-bottom: 30px; font-size: 18px!important;}
.title-content {position: relative; margin-top:40px;}

.title-content h1{ margin-bottom: 30px; font-size: 34px!important; text-align: center; letter-spacing: 1px;
  
  &:not(.simple) {
    span { position: relative;
      &:first-letter {
        font-size : 55px;
      }
      &:after {
        content:"";
        border-bottom: solid 3px;
        border-color: inherit;
        position: absolute;
        left:0; right:50%;
        bottom:0px; overflow: hidden;
      }
    }
  }
}
.text-banner {font-size: 40px !important;}
.text-content {font-size: 14px !important;}
.text-white {color:white!important;}
.text-black {color:black!important;}
.text-gray {color:#7c7979!important;}
.center-img {vertical-align: middle;}
.relative {position:relative;}
.fixed {position:fixed;}
.layer-dark {position:absolute; top:0; bottom:0; right:0; left:0; background-color: rgba(0,0,0,0.5);}
.layer-light {position: absolute; top:0; left:0; right: 0; bottom: 0; background-color: rgba(255,255,255,0.5);}
.img-thumbnail {border-radius: 0; border-width: 3px;}

.gray-hover:hover, .grayscale {
    filter: grayscale(100%);
}

.text-10 {font-size: 10px;}
.text-12 {font-size: 12px;}
.text-14 {font-size: 14px;}
.text-16 {font-size: 16px;}
.text-18 {font-size: 18px;}
.text-20 {font-size: 20px;}
.text-22 {font-size: 22px;}
.text-24 {font-size: 24px;}
.text-80 {font-size: 80px;}


.transitioner {
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}
.rotation {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}


.text-shadow {text-shadow: 1px 1px 2px black;}
.font-secondary {font-family: $secondary-font;}
.font-primary {font-family: $primary-font;}

.border-2 {border-width: 2px !important;}
.border-3 {border-width: 3px !important;}
.border-4 {border-width: 4px !important;}

.borderPrimary {border-color: $color-primary !important;}

.style-for-faq {
  background-color: $color-primary-light;
  color: white;
  top: -23px;
  position: relative;
  z-index: 1;
  min-height: 70px;
  padding: 2em;
  padding-top: 3em;
  border-radius: 0px 0px 30px 30px;
}


a {
  // color: $white;
  &:hover {
    color: $color-secondary-light;
    text-decoration: none;
  }
  &:focus,
  &:active {
    color: $color-secondary;
    // color: darken($color-primary, 10%);
    // text-decoration: $color-primary underline;
  }
}
.a-primary {
  background-color: $color-primary !important;
  border-radius: 0.25rem !important;
  &:hover {
    color: $color-secondary-light;
    text-decoration: none;
    background-color: $color-primary-light !important;
  }
  &:focus,
  &:active {
    color: $color-secondary;
    // color: darken($color-primary, 10%);
    // text-decoration: $color-primary underline;
  }
}
.active {color: $color-secondary;}

.h-200 {height: 200px;}
.h-300 {height: 300px;}
.h-400 {height: 400px;}
.h-500 {height: 500px;}
.h-800 {height: 800px;}

.btn {
  border-width: 2px;
}

.bg-primary {
  background-color: $color-primary !important;
  h1,h2 {color:#FFF;}
}

.bg-primary-light {
  background-color: $color-primary-light !important;
}

.bg-black {
  background-color: #000 !important;
  h1,h2 {color:#FFF;}
}

.bg-secondary {
  background-color: $color-secondary !important;
  h1,h2 {color:#FFF;}
}

.bg-secondary-light {
  background-color: $color-secondary-light !important;
}

.text-primary {
  color: $color-primary !important;
  h1,h2 {color:#FFF;}
}

.text-secondary {
  color: $color-secondary !important;
}
.text-secondary-light {
  color: $color-secondary-light !important;
}
.text-secondary-dark {
  color: $color-secondary-dark !important;
}
.text-gray-600 {
  color: $gray-600 !important;
}

.btn-primary {
  background-color: $color-primary;
  color: white;
  font-weight: 600;
  border-color: $color-primary;
  &:disabled{
	  background-color: #EEE!important;
	  border-color: #EEE!important;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-primary, 10%);
    border-color: darken($color-primary, 10%);
    color: white;
  }
}

.btn-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: white;
  font-weight: 600;
  &:disabled{
	  background-color: #EEE!important;
	  border-color: #EEE!important;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-secondary, 10%);
    border-color: darken($color-secondary, 10%);
    color: white;
  }
}
.border-secondary {
  border-color: $color-secondary !important;
}

.square {
    width: 100%!important;
    padding-bottom: 100%!important;
    background-repeat: no-repeat!important; 
    background-position:center center!important;
    background-size: cover!important;
}

.virgule-top {
  width: 140px;
  transform: rotate(-7deg);
  position: relative;
  top: 25px;
  right: -50px;
}
.virgule-bottom {
  width: 140px;
  transform: rotate(-7deg);
  position: relative;
  bottom: 48px;
  left: -40px;
}
.fond-casino {
  background-image: url("../../images/fond_casino_orange.png");
}



textarea:hover, input, input:after, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
    outline:0px !important;
    //-webkit-appearance:none !important;
    box-shadow: none;
}


.pointer {cursor: pointer;}
.bg-white { background:white; }
.bg-black-50 { background:rgba(0,0,0,0.5); }
.bg-alternate { background-color:$color-alternate; }
.bg-transparent { background-color:transparent }

#remonte {
    position:fixed; 
    //right:calc(50% - 30px); 
    right:30px;
    bottom:20px; cursor:pointer;  opacity:0.8; z-index:1000;
    transform-origin: center;
    font-size: 60px;
    color:$color-secondary;
    padding: 0 15px;
    line-height: 40px;

    &:hover {
        opacity:1;
        // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    
    &.active {
        transform: rotate(180deg);
    }
}

@media only screen and (min-width:768px) {
  .text-banner {font-size: 60px !important;}
}

 @media only screen and (min-width:992px) {
     body {padding-top:$header-height;}
    .text-banner {font-size: 80px !important;}
    h1 {
        font-family: $secondary-font;
        font-weight: 300;
        color : $color-secondary;
        margin-bottom: 30px;
    }
    .title-content h1{ margin-bottom: 30px; font-size: 34px!important;
      &:first-letter {
        font-size : 55px;
      }
    }
    .text-content {font-size: 16px !important;}
     
    .anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
    .anim_slow { -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
    [data-link] {cursor: pointer;}
    a { @extend .anim; }
    .zoom {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            z-index:1000;
            transform: scale(1.1);
            cursor: pointer;
        //border:solid 2px #FFF;
        //box-shadow: 3px 3px 10px #222;
        }
    }
    .zoom-1 {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            transform: scale(1.05);
            cursor: pointer;
        }
    }
    .zoom-out {
        @extend .anim;
        &:hover {
            display: inline-block;
            transform: scale(0.95);
            cursor: pointer;
        }
    }
}



#footer-new .img-fluid {
	margin:0 0 20px 0;
}
.footer-title {
	font-size:14px;
	padding:0 0 25px 0;
	margin:0;
	font-weight: 500;
	text-transform:uppercase;
	letter-spacing:2px;
	h2 {color:white !important;}
	// h2 {color:#555!important;}
}
#footer-new p {
	color:#fff;
	font-size:12px;
	padding:0;
	margin:0;
	font-family: $color-primary;
	letter-spacing:1px;
}

footer {
	width:100%;
  ul {
    list-style:none;
    margin:0 0 0 -40px;
  }
  li {
    width:100%;
    padding:0 0 10px 32px;
    font-size:12px;
    letter-spacing:1px;
  }
}







// carousel 3d
* { box-sizing: border-box; }

body {
  font-family: $primary-font;
}

.scene {
  // border: 1px solid #CCC;
  margin: 0px auto;
  position: relative;
  //height: 277px; // 140px
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel__cell {
  position: absolute;
  // width: 600px; // 190px
  // height: 253px; // 120px
  left: 10px;
  top: 10px;
  transition: transform 1s, opacity 1s;
}

.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
}