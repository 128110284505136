
:root {
	--white: #ffffff;
	--black: #000000;
	--blue:#284e7b;
	--gray:#ebebeb;
	--box-shadow1:0px 0px 18px 2px rgba(10, 55, 90, 0.15);
}

.timeline {
    padding: 50px 0;
    position: relative;
}
.timeline-nodes {
        padding-bottom: 25px;
        position: relative;
    }
.timeline-nodes:nth-child(odd) {
    flex-direction: row-reverse;
}
.timeline h3, .timeline p {
    padding: 5px 15px;
} 
.timeline h3{
    font-weight: lighter;
    background: var(--blue);
}
.timeline p, .timeline time {
    color: var(--blue)
}
.timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 6px solid var(--white);
    height: 100%;
    z-index: 1;
    transform: translateX(-50%);
}
.timeline-content {
    //border: 1px solid var(--white);
    position: relative;
    //border-radius: 0 0 10px 10px;
    box-shadow: 0px 3px 25px 0px rgba(10, 55, 90, 0.8);
    cursor: pointer;
    p {
        position: absolute;
        padding: 8px 15px;
        bottom: 0;
        right: 0; left:0;
        margin: 0;
        background: white;
        color: #333;
        font-family: sans-serif;
        font-weight: 500;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: visibility 0s, opacity 0.3s linear; 
        transition: visibility 0s, opacity 0.3s linear;
    }
}

.timeline-content:hover {
    p {
        visibility: visible;
        opacity: 0.95; 
    }
}

.timeline-nodes:nth-child(even) h3,
.timeline-nodes:nth-child(even) p {
    text-align: center;
}
.timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
}
 
.timeline-nodes:nth-child(odd) .timeline-date {
    text-align: right;
}
.timeline-nodes:nth-child(even) .timeline-content::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 100%;
    width: 0;
    border-left: 10px solid var(--white);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-nodes:nth-child(odd) .timeline-content::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 100%;
    width: 0;
    border-right: 10px solid var(--white);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-image {
    position: relative;
    z-index: 100;
}
.timeline-image::before {
    content: "";
    width: 80px;
    height: 80px;
    //border: 2px solid var(--blue);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    z-index: 1;
    

}
.timeline-image span {
    position: relative;
    color: var(--blue);
    font-weight: 500;
    display: inline-block;
    line-height: 16px;
    z-index: 100;
}
/*small device style*/

@media (max-width: 767px) {

    .timeline-content p {
        opacity: 0.95;
        visibility: visible;
    }

    .timeline-image span {
        position: relative;
        color: var(--blue);
        font-weight: 500;
        display: inline-block;
        line-height: 14px;
        font-size: 13px;
        z-index: 100;
    }

    .timeline-nodes:nth-child(even) h3,
        .timeline-nodes:nth-child(even) p {
        text-align: center
    }
    .timeline-nodes:nth-child(odd) {
        flex-direction: row;
    }
        .timeline::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 4%;
        width: 0;
        border-left: 4px solid var(--white);
        height: 100%;
        z-index: 1;
        transform: translateX(-50%);
    }
    .timeline h3 {
        font-size: 1.7rem;
    }
    .timeline p {
        font-size: 14px;
    }
    .timeline-image {
        position: absolute;
        left: 0%;
        top: 60px;
        /*transform: translateX(-50%;);*/
    }
    .timeline-nodes:nth-child(even) .timeline-content::before {
        content: "";
        position: absolute;
        top: 5px;
        left: auto;
        right: 100%;
        width: 0;
        border-left: 0;
        border-right: 10px solid var(--blue);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .timeline-nodes:nth-child(odd) .timeline-content::before {
        content: "";
        position: absolute;
        top: 5%;
        right: 100%;
        width: 0;
        border-right: 10px solid var(--blue);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .timeline-nodes:nth-child(odd) .timeline-date {
        text-align: left;
    }
    .timeline-image::before {
        width: 65px;
        height: 65px;
    }               
}

/*extra small device style */
@media (max-width: 575px) {
    .timeline::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 3%;
    }
    .timeline-image {
        position: absolute;
        left: -5%;
        }
    .timeline-image::before {
        width: 60px;
        height: 60px;
    }
}


