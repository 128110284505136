// Fonts
@import "resources/assets/sass/front/fonts.scss";
@import "variables";
@import "~bootstrap/scss/bootstrap";
// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import "animate";

@import "~lightgallery/src/sass/lightgallery";
@import "~lightslider/dist/css/lightslider.min.css";
@import "lightgallery"; // custom styles
@import "resources/assets/libs/jquery-sweetalert/sweetalert.scss";
@import "resources/assets/libs/wow_book/wow_book.scss";
//@import "resources/assets/libs/bootstrap-select/bootstrap-select.scss";
//@import "resources/assets/libs/bootstrap-switch/bootstrap-switch.min.scss";

// CustomScrollBar
@import "node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar";

// Preloader
@import "../preloader";
@import "general";
@import "navbar";
@import "timeline";
//@import "avoventes";
