// Styling for the navbar

// LOGO CENTERED

$nth-nav-item-that-needs-margin-right     : 2;
$width-of-navbar-brand-or-logo-in-px      : 300px;
$padding-x-for-navbar-brand-or-logo-in-px : 40px;
@media (max-width:767px) {
    #mainNav .navbar-brand img {max-height: $logo-mobile-height!important;}
    #mainNav .navbar-nav li.nav-item a.nav-link {color: #555!important; cursor: pointer;}
    body {padding-top: 30px!important;}
}

/* Style page content */
.main {
    margin-left: ($sidebar-width + 2*$sidebar-left); 
}

.sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: $sidebar-width + 2*$sidebar-left; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: rgba(219,208,192,0.2);
    //overflow-x: hidden; /* Disable horizontal scroll */
    padding-left: $sidebar-left!important;
    padding-right: $sidebar-left!important;
    
    .navbar-nav {
        width: 100%;
        border: solid 1px $color-primary;
        padding-top: 15px;
        letter-spacing: 0.0625rem;
        li.nav-item {
            cursor: pointer;
            a.nav-link {
                font-size: 17px;
                line-height: 14px;
                color: #555;
                font-family: $primary-font;
                font-weight: 300;
                background-color: transparent;
                padding: 20px 15px 10px 15px;
                
                &:hover {
                    color: $color-primary;
                }
                &:active,
                &:focus {
                    color: $color-primary;
                }
            }
            .dropdown-item {
                color: #555;
                background-color: transparent;
            }
            &:not(:last-child) {
                border-bottom: 0;
            }
        }
    }
    .side-menu {
        width: 100%;
        background-color: #3c4b64;
        list-style: none;
        margin: 0;
        padding-top: $header-height!important;
        padding-left:0px!important;
        letter-spacing: 0.0625rem;
        li.nav-item {
            cursor: pointer;
            a.nav-link {
                font-size: 12px!important;
                line-height: 18px;
                color: #f6f6f6;
                font-family: $primary-font;
                font-weight: 300;
                background-color: transparent;
                padding: 20px 15px 10px 15px;
                
                &:hover {
                    color: $color-primary;
                }
                &:active,
                &:focus {
                    color: $color-primary;
                }
            }
            .dropdown-item {
                color: #555;
                background-color: transparent;
            }
            &:not(:last-child) {
                border-bottom: 0;
            }
        }
    }
    .dropright .dropdown-menu {
        border: solid 1px $color-primary;
        border-radius: 0;
        margin: 0;
        min-width: 220px;
        font-size: 14px;
        
    }
}

/* The navigation menu links */

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
    color: #333;
}



/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}


@media (min-width:768px) {
	.position-md-absolute {
		position: absolute;
	}
	.navbar-nav.logo-centered .nav-item:nth-child(#{$nth-nav-item-that-needs-margin-right}) {
		margin-right: $width-of-navbar-brand-or-logo-in-px + ($padding-x-for-navbar-brand-or-logo-in-px * 2);
	}
	.navbar-nav .navbar-brand img {max-height: $logo-height;}
    #logoLargeCenter {
        left : 0 - ($width-of-navbar-brand-or-logo-in-px / 2);
        img {
            width:$width-of-navbar-brand-or-logo-in-px;
        }
    }
}

.footer-bottom { position: fixed; bottom: 0; left:0; width:100vw; z-index: 100;}

#mainNav {
    font-family: $secondary-font;
    box-shadow: 0px 1px 10px #444;
    
    .navbar-brand {
        color: #555;
        img {max-height: $logo-height;}
    }
    .navbar-nav {
        letter-spacing: 0.0625rem;
        li.nav-item {
            cursor: pointer;
            a.nav-link {
                @extend .anim;
                color: white;
                font-size: 13px;
                //background-color: $color-primary;
                border-top: solid 8px transparent;
                &:hover {
                    color: $color-secondary;
                }
                &:active,
                &:focus {
                    color: $color-primary;
                }
                
            }
            &:not(:last-child) {
                border-bottom: solid 1px #f9f9f9;
            }
            .dropdown-menu {
                padding: 0;
                margin:  0;
            }
            .dropdown-item {
                display: block;
                width: 100%;
                clear: both;
                color: #FFF;
                text-align: inherit;
                white-space: nowrap;
                font-size: 13px;
                background-color: $color-primary;
                opacity: 0.9;
                padding : 15px 20px;
            }
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform:translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}


@media(min-width:768px) {
    #mainNav {
        height: $header-height;
        -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
        -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
        transition: padding-top 0.3s, padding-bottom 0.3s;
        .navbar-brand {
            padding-top:0; padding-bottom: 0;
            font-size: 2em;
            -webkit-transition: font-size 0.3s;
            -moz-transition: font-size 0.3s;
            transition: font-size 0.3s;
        }
        .navbar-nav {
            letter-spacing: 0.0625rem;
            li.nav-item {
                cursor: pointer;
                a.nav-link {
                    width:150px;
                    font-size: 13px;
                    color: #555;
                    background-color: transparent;
                    
                    &:hover {
                        color: $color-primary;
                    }
                    &:active,
                    &:focus {
                        color: $color-primary;
                    }
                    &.active {
                        color: $color-primary;
                    }
                }
                .dropdown-item {
                    color: #555;
                    background-color: transparent;
                }
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
    }
}